<template>
  <div class="main-content auth">
    <div class="auth-container box">
      <Switch :switchValue="!switchValue" @switchValueChanged="switchValue = !switchValue"/>
      <img class="auth__logo" src="@/assets/images/logos/LogoMeaning.svg">
      <div class="auth__titles">
        <h2>{{$t('recoverpassword.nuevacontratitle')}}</h2>
        <p class="subtitle">{{$t('recoverpassword.ingresanuevacontra')}}</p>
      </div>
      <div class="auth__form">
        <div class="auth__own">
          <form @submit.prevent="resetPassword()">
            <div class="form__group">
              <label class="form__label" for="password">{{$t('recoverpassword.nuevacontra')}}</label>
              <input class="form__input" id="password" type="password" v-model="password" required autofocus>
            </div>
            <div class="form__group">
              <label class="form__label" for="password-confirm">{{$t('recoverpassword.confirmarnuevacontra')}}</label>
              <input class="form__input" id="password-confirm" type="password" v-model="passwordConfirm" required>
            </div>
            <div class="form__actions">
              <button class="btn btn--primary btn--block" type="submit" :disabled="!password || password !== passwordConfirm">{{$t('recoverpassword.cambiarcontra')}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Switch from '../components/Switch.vue'
export default {
  components: { Switch },
  name: 'RecoverPassword',
  data () {
    return {
      id: null,
      password: '',
      passwordConfirm: ''
    }
  },
  methods: {
    async resetPassword () {
      const res = await this.$axios.post('auth/reset-password', {
        id: this.id,
        password: this.password,
        token: this.$route.params.token
      })
      if (res.data.ok) {
        alert(this.$t('recoverpassword.alertchangepass'))
        this.$router.push('/')
      } else {
        alert(this.$t('recoverpassword.linkcaducado'))
        this.$router.push({ name: 'RequestPasswordRecovery' })
      }
    }
  },
  async beforeMount () {
    const res = await this.$axios.get(`auth/user-by-reset-password-token/${this.$route.params.token}`)
    if (res.data.user) {
      this.user.id = res.data.invite.id
      this.user.email = res.data.invite.email
    } else {
      alert(this.$t('recoverpassword.linkcaducado'))
      this.$router.replace('/')
    }
  }
}

</script>
